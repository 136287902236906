import React, { useState } from "react"
import logo from "./logo.svg"
import "./App.css"

function App() {
  const [count, setCount] = useState(0)

  function one() {
    const mod = count % 3
    switch (mod) {
      case 0: {
        console.log(`log(${count})`)
        break
      }
      case 1: {
        console.warn(`warn(${count})`)
        break
      }
      case 2: {
        throw new Error(`error(${count})`)
      }
    }
  }

  function two() {

    try {
      one()
    } catch (e) {
      console.error(e)
    } finally {
      setCount(count + 1)
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <button onClick={two}>Hello</button>
      </header>
    </div>
  );
}

export default App;
